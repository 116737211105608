<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import {debounce} from 'lodash';
import Swal from "sweetalert2";

let isScrolled = ref(false);
let BASE_URL = process.env.VUE_APP_ORDER_URL

let Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
});

const handleScroll = () => {
  isScrolled.value = window.scrollY > 50;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

onMounted(async () => {
  await getDocument()
  await getForwarders()
  await checkCodes()
})

const route = useRoute()
let showConfirmConfirmationModal = ref(false)
let showUploadExcelModal = ref(false)
let is_downloading_excel = ref(false)
let document = ref(null)
let selected_row = ref(null)
let missing_codes = ref([])
let existing_codes = ref(0)
let confirm_state = ref('needs_confirmation')

let container_options = ref([])
let wagon_options = ref([])
let departure_options = ref([])
let destination_options = ref([])
let shipper_options = ref([])
let consignee_options = ref([])
let forwarders_options = ref([])

const getDocument = async () => {
  try {
    let res = await axios.get(`/smgs/document/by/${route.params.id}/`)
    document.value = res.data
    document.value.smgses = (document.value.smgses || []).map(i => {
      return {
        ...i,
        smgs_codes: (i.smgs_codes || []).map(item => {
          return {
            id: item.id,
            forwarder_id: item.forwarder_id,
            code: item.code || null,
            cost: parseFloat(item.cost || 0),
            forwarder: item.forwarder ? {
              value: item.forwarder.id,
              label: item.forwarder.name,
            } : null
          }
        })
      }
    })
    if (!selected_row.value) {
      selectPage(0)
    }
  } catch {
    document.value = null
    await Toast.fire({
      icon: "error",
      title: "Oops..",
      text: "Failed to load document",
      timer: 3000
    })
  }
}

const getForwarders = async () => {
  try {
    let res = await axios.get('/counterparty/counterparties/', {
      params: {
        is_used_for_code: true
      }
    })
    forwarders_options.value = (res.data.results || []).map(item => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  } catch {
    forwarders_options.value = []
  }
}

const saveSelectedSMGS = async () => {
  selected_row.value.is_saving = true

  await checkCodes()

  const result = await hasIssuesInSmgsCodes(selected_row.value.smgs_codes);

  if (result.hasIssues) {
    selected_row.value.validation = result
    return await Toast.fire({
      icon: "error",
      title: "Error",
      text: "Validation error",
      timer: 3000
    })
  } else {
    selected_row.value.validation = null
  }

  if (selected_row.value.smgs_codes.some(i => missing_codes.value.includes(i.code))) {
    selected_row.value.validation = {
      hasIssues: true,
      reason: "Code does not exist in system!"
    }
    return
  }

  try {
    await axios.put(`/smgs/update/${selected_row.value.id}/`, {
      index: selected_row.value.index,
      number: selected_row.value.number,
      date: selected_row.value.date,
      departure_station_id: selected_row.value.departure_station_id,
      destination_station_id: selected_row.value.destination_station_id,
      wagon_id: selected_row.value.wagon_id
          ? selected_row.value.wagon_id.value
          : null,
      container_id: selected_row.value.container_id,
      consignee_id: selected_row.value.consignee_id,
      shipper_id: selected_row.value.shipper_id,
      weight: selected_row.value.weight,
      smgs_codes: selected_row.value.smgs_codes,
      comment: selected_row.value.comment,
    })
    selected_row.value.is_saving = false

    await getDocument()
    selectPage(selected_row.value.index + 1)
    await checkCodes()
    await Toast.fire({
      icon: "success",
      title: "Success",
      timer: 3000
    })
  } catch {
    selected_row.value.is_saving = false
    await Toast.fire({
      icon: "error",
      title: "Oops..",
      text: "Something went wrong",
      timer: 3000
    })
  } finally {
    selected_row.value.is_saving = false
  }
}

const handleConfirm = async () => {
  try {
    const smgs_code_ids = [];
    const forwarder_ids = [];
    const code_numbers = [];
    const code_smgs_obj = [];

    const validCodes = document.value.smgses
        .flatMap(obj => obj.smgs_codes)
        .filter(codes => codes.code);

    validCodes.forEach(codes => {
      smgs_code_ids.push(codes.id);
      forwarder_ids.push(codes.forwarder_id);
      code_numbers.push(codes.code);
      code_smgs_obj.push({smgs_code_id: codes.id, code: codes.code});
    });

    confirm_state.value = 'confirming';

    await axios.put('/smgs/sync/code/', {
      forwarder_ids: forwarder_ids,
      codes: code_numbers,
      smgs_code_ids: smgs_code_ids,
      code_smgs: code_smgs_obj,
    })

    confirm_state.value = 'confirmed';

    setTimeout(() => {
      resetConfirm()
    }, 5000)
  } catch {
    confirm_state.value = 'failed_to_confirm';
  }
}

const handleExcelUpload = async () => {
  try {
    const formData = new FormData();
    formData.append('file', window.document.getElementById('xlsxfile').files[0]);
    await axios.post('/smgs/xlsx/process/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    await Toast.fire({
      icon: "success",
      title: "Success",
      text: "Successfully uploaded",
      timer: 3000
    })
  } catch {
    await Toast.fire({
      icon: "error",
      title: "Oops..",
      text: "Something went wrong",
      timer: 3000
    })
  }
}

const handleDownloadExcel = async () => {
  let url = `${process.env.VUE_APP_ORDER_URL}/smgs/xlsx/download/${document.value.id}/`;
  is_downloading_excel.value = true;

  try {
    const response = await axios.get(url, {responseType: 'blob'}); // Fetch file as a Blob
    const blob = new Blob([response.data]);
    const blobUrl = window.URL.createObjectURL(blob);

    const anchor = window.document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = blobUrl;
    anchor.download = `${document.value.name.replace('.pdf', '')}.xlsx`; // Ensure correct filename
    window.document.body.appendChild(anchor);
    anchor.click();

    window.URL.revokeObjectURL(blobUrl); // Cleanup
    window.document.body.removeChild(anchor);
  } catch (error) {
    setTimeout(() => {
      is_downloading_excel.value = false;
    }, 750);
    await Toast.fire({
      icon: "error",
      title: "Oops..",
      text: "Something went wrong",
      timer: 3000
    });
  } finally {
    setTimeout(() => {
      is_downloading_excel.value = false;
    }, 750);
  }
};

const checkCodes = async () => {
  try {
    let res = await axios.post('/smgs/check/code/', {
      codes: document.value.smgses.flatMap(row => row.smgs_codes)
    })
    missing_codes.value = (res.data.not_exist || []).filter(i => i)
    existing_codes.value = res.data.exist || 0
  } catch {
    missing_codes.value = []
  }
}

const searchContainers = async (query) => {
  if ((query || '').length <= 2) return;
  await debounce(async () => {
    try {
      let res = await axios.get('/core/containers/', {
        params: {
          name: query,
          limit: 7
        }
      })
      const results = (res.data.results || []).map(item => {
        return {
          value: item.id,
          label: item.name,
        }
      })

      container_options.value = [
        ...container_options.value,
        ...results.filter(newOpt =>
            !container_options.value.some(existingOpt => existingOpt.value === newOpt.value)
        )
      ];
    } catch {
      container_options.value = []
    }
  }, 500)()
}

const onWagonSelect = async (wagon) => {
  if (wagon) {
    if (!wagon.is_new) return
    try {
      let response = await axios.post('/core/wagons/create/', {
        name: wagon.label
      })
      wagon_options.value.unshift({
        value: response.data.id,
        label: response.data.name,
        is_new: false
      })
      wagon_options.value = wagon_options.value.filter(i => i.value !== wagon.value)
      selected_row.value.wagon_id = {
        value: response.data.id,
        label: response.data.name,
        is_new: false
      }
      await Toast.fire({
        icon: "success",
        title: wagon.label,
        text: "New wagon created successfully",
      })
    } catch {
      console.log('error creating wagons');
    }
  }
}

const searchWagons = async (query) => {
  if ((query || '').length <= 2) return;

  await debounce(async () => {
    try {

      // clears suggested wagons names from the list
      wagon_options.value = wagon_options.value.filter(i => !i.is_new);

      let res = await axios.get('/core/wagons/', {
        params: {name: query, limit: 7}
      });

      const results = (res.data.results || []).map(item => {
        return {
          value: item.id,
          label: item.name,
          is_new: false
        }
      });

      if (results.length === 0 && query.trim().length > 6) {
        results.push({
          value: query.trim(),
          label: query.trim(),
          is_new: true
        });
      }

      wagon_options.value = [
        ...wagon_options.value,
        ...results.filter(newOpt =>
            !wagon_options.value.some(existingOpt =>
                existingOpt.value === newOpt.value
            )
        )
      ];
    } catch (error) {
      container_options.value = [];
      console.error("Error fetching wagons:", error);
    }
  }, 1200)();
}

const searchStations = async (query, station_type) => {
  if ((query || '').length <= 2) return;
  await debounce(async () => {
    try {
      let res = await axios.get('/core/stations/', {
        params: {
          search: query,
          limit: 7
        }
      })
      const results = (res.data.results || []).map(item => {
        return {
          value: item.id,
          label: `${item.name} (${item.code})`,
        }
      })

      if (station_type === 'departure') {
        departure_options.value = [
          ...departure_options.value,
          ...results.filter(newOpt =>
              !departure_options.value.some(existingOpt => existingOpt.value === newOpt.value)
          )
        ];
      } else {
        destination_options.value = [
          ...destination_options.value,
          ...results.filter(newOpt =>
              !destination_options.value.some(existingOpt => existingOpt.value === newOpt.value)
          )
        ];
      }
    } catch {
      if (station_type === 'departure') {
        departure_options.value = []
      } else {
        destination_options.value = []
      }
    }
  }, 500)()
}

const searchCompanies = async (query, company_type) => {
  if ((query || '').length <= 2) return;

  await debounce(async () => {
    try {
      let res = await axios.get('/customer/companies/', {
        params: {
          search: query,
          limit: 7
        }
      })
      const results = (res.data.results || []).map(item => {
        return {
          value: item.id,
          label: item.name,
        }
      })

      if (company_type === 'shipper') {
        shipper_options.value = [
          ...shipper_options.value,
          ...results.filter(newOpt =>
              !shipper_options.value.some(existingOpt => existingOpt.value === newOpt.value)
          )
        ];
      } else {
        consignee_options.value = [
          ...consignee_options.value,
          ...results.filter(newOpt =>
              !consignee_options.value.some(existingOpt => existingOpt.value === newOpt.value)
          )
        ];
      }
    } catch {
      if (company_type === 'shipper') {
        shipper_options.value = []
      } else {
        consignee_options.value = []
      }
    }
  }, 500)()
}

const selectPage = (page_index) => {
  if (!document.value) return;
  try {
    const page = (document.value.smgses || [])[page_index]

    if (!page) return;

    let page_img_path = `${BASE_URL}/${page.image_path || ''}`
    const img_type = page_img_path.endsWith(".pdf") ? 'pdf' : 'png'

    selected_row.value = {
      ...page,
      index: page_index,
      is_loading: true,
    }

    if (page_img_path.endsWith(".pdf")) {
      axios.get(page_img_path, {
        responseType: 'arraybuffer'
      }).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        page_img_path = URL.createObjectURL(blob);
      }).catch(error => {
        console.log(error)
        console.log("error", error)
      });
    }

    if (page.container_id && !container_options.value.some(option => option.value === page.container_id)) {
      container_options.value.unshift({value: page.container_id, label: page.container_name});
    }

    if (page.wagon_id && !wagon_options.value.some(option => option.value === page.wagon_id)) {
      wagon_options.value.unshift({value: page.wagon_id, label: page.wagon_name});
    }

    if (page.departure_station_id && !departure_options.value.some(option => option.value === page.departure_station_id)) {
      departure_options.value.unshift({
        value: page.departure_station.id,
        label: `${page.departure_station.name} (${page.departure_station.code})`
      });
    }

    if (page.destination_station_id && !destination_options.value.some(option => option.value === page.destination_station_id)) {
      destination_options.value.unshift({
        value: page.destination_station.id,
        label: `${page.destination_station.name} (${page.destination_station.code})`
      });
    }

    if (page.shipper_id && !shipper_options.value.some(option => option.value === page.shipper_id)) {
      shipper_options.value.unshift({value: page.shipper_id, label: page.shipper_name});
    }

    if (page.consignee_id && !consignee_options.value.some(option => option.value === page.consignee_id)) {
      consignee_options.value.unshift({value: page.consignee_id, label: page.consignee_name});
    }

    if (selected_row.value.smgs_codes.length === 0) {
      addForwarder()
    }

    setTimeout(() => {
      selected_row.value = {
        ...page,
        index: page_index,
        is_loading: false,
        img_type: img_type,
        img_path: page_img_path,
      }
      copySelectedRowDate(page)
      copySelectedRowStations(page)
    }, 100)
  } catch {
    selected_row.value = null
    Toast.fire({
      icon: "error",
      title: "Oops..",
      text: `Failed to select page: ${page_index + 1}`,
      timer: 3000
    })
  }
}

const copySelectedRowDate = (page) => {
  if (!page.date) {
    const firstItem = document.value.smgses[0];
    if (firstItem && firstItem.date) {
      selected_row.value.date = firstItem.date;
    }
  }
}

const copySelectedRowStations = (page) => {
  const updateStation = (stationType, options, selectedRowKey) => {
    if (!page[selectedRowKey]) {
      const firstItem = document.value.smgses[0];
      if (firstItem) {
        const station = firstItem[stationType];
        if (station) {
          if (!options.value.some(option => option.value === station.id)) {
            options.value.unshift({
              value: station.id,
              label: `${station.name} (${station.code})`
            });
          }
          selected_row.value[selectedRowKey] = station.id;
        }
      }
    }
  };

  updateStation("departure_station", departure_options, "departure_station_id");
  updateStation("destination_station", destination_options, "destination_station_id");
};


const rowIsSelected = (page_index) => {
  try {
    return page_index === (selected_row.value ? selected_row.value.index : -1)
  } catch {
    return false
  }
}

const addForwarder = () => {
  if (selected_row.value) {
    selected_row.value.smgs_codes.push({
      forwarder_id: null,
      forwarder: null,
      code: null,
      cost: 0,
      id: 0,
    })
  }
}

const removeForwarder = async (index) => {
  selected_row.value.smgs_codes.splice(index, 1)
  const result = hasIssuesInSmgsCodes(selected_row.value.smgs_codes);
  selected_row.value.validation = result.hasIssues ? result : null
}

const hasIssuesInSmgsCodes = async (smgsCodes) => {
  const seenForwarderIds = new Set();

  for (const {code, cost, forwarder_id} of smgsCodes) {
    if (!code || !forwarder_id) {
      return {hasIssues: true, reason: "Missing required values"};
    }

    if (typeof cost !== "number" || cost < 0 || isNaN(cost)) {
      return {hasIssues: true, reason: "Invalid cost value"};
    }

    if (seenForwarderIds.has(forwarder_id)) {
      return {hasIssues: true, reason: "Duplicate forwarder found"};
    }

    seenForwarderIds.add(forwarder_id);
  }

  return {hasIssues: false, reason: "No issues found"};
};

const onForwarderSelect = (event, forwarder, index) => {
  if (event) {

    selected_row.value.smgs_codes[index] = {
      ...forwarder,
      forwarder: event,
      forwarder_id: event.value,
    };

    Toast.fire({
      icon: "success",
      title: "Success",
      timer: 3000
    })
  } else {
    selected_row.value.smgs_codes.splice(index, 1)
    if (selected_row.value.smgs_codes.length === 0) {
      addForwarder()
    }
  }
}

const resetConfirm = () => {
  showConfirmConfirmationModal.value = false
  setTimeout(() => {
    confirm_state.value = 'needs_confirmation'
  }, 200)
}

const disableConfirm = computed(() => document.value.smgses.some(i => {
  let result = hasIssuesInSmgsCodes(i.smgs_codes)
  return result.hasIssues
}))
</script>

<template>
  <b-modal v-model="showConfirmConfirmationModal" hide-footer class="v-modal-custom" body-class="p-0" centered
           hide-header-close>
    <div class="text-center px-5 pt-4">
      <div class="text-end">
        <button type="button" class="btn-close text-end" @click="showConfirmConfirmationModal = false"
                data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="mt-2">
        <template v-if="confirm_state === 'needs_confirmation'">
          <h4 class="mb-3 mt-4">
            Are you sure you want to confirm?
          </h4>
          <p class="text-muted fs-15 mb-4">
            Please take a moment to review all the details carefully before proceeding.
          </p>
          <div class="hstack gap-2 justify-content-center mb-5">
            <b-button variant="success" @click="handleConfirm()">
              Yes, Confirm
            </b-button>
            <b-button variant="light" @click="showConfirmConfirmationModal = false">
              No, Cancel
            </b-button>
          </div>
        </template>
        <template v-else-if="confirm_state === 'confirming'">
          <b-spinner variant="success"></b-spinner>
          <p class="text-muted fs-18 my-4 mb-5">
            Please, wait..
          </p>
        </template>
        <template v-else-if="confirm_state === 'confirmed'">
          <i class="bx bx-message-square-check h1 text-success"></i>
          <p class="text-muted fs-18 my-4">
            Successfully confirmed
          </p>
          <b-button @click="resetConfirm()" class="mb-4" variant="soft-success">Ok</b-button>
        </template>
        <template v-else-if="confirm_state === 'failed_to_confirm'">
          <i class="bx bx-message-square-x h1 text-danger"></i>
          <p class="text-danger fs-18 my-4">
            Failed to confirm
          </p>
          <b-button @click="resetConfirm()" class="mb-4" variant="light">Try again</b-button>
        </template>
      </div>
    </div>
  </b-modal>

  <b-modal v-model="showUploadExcelModal" hide-footer title="Import smgses from XLSX" class="v-modal-custom" centered>
    <form @submit.prevent="handleExcelUpload">
      <div class="mb-3">
        <label for="xlsxfile" class="form-label">XLSX</label>
        <input id="xlsxfile" class="form-control" type="file"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
      </div>
      <div class="d-flex justify-content-end gap-2">
        <b-button type="button" variant="light" @click="showUploadExcelModal = false">
          Close
        </b-button>
        <b-button type="submit" variant="success">Submit</b-button>
      </div>
    </form>
  </b-modal>

  <b-card>
    <div v-if="document" class="row align-items-start min-vh-100">
      <div class="col-12">
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <h4>{{ document.name }}</h4>
            <p v-if="selected_row">
              Selected Page Number: {{ selected_row.index + 1 }}/{{ (document.smgses || []).length }}
            </p>
          </div>
          <div class="d-flex gap-2 align-items-start">
            <BButton @click="showUploadExcelModal = true" variant="soft-success"
                     class="btn-label waves-effect right waves-light"><i
                class="mdi mdi-upload label-icon align-middle fs-16 ms-2"></i> Upload
            </BButton>
            <BButton
                :disabled="is_downloading_excel"
                @click="handleDownloadExcel()" variant="soft-success"
                class="btn-label waves-effect right waves-light"
            >
              <i :class="{
                  'mdi-spin mdi-loading': is_downloading_excel,
                  'mdi-download': !is_downloading_excel,
                }" class="mdi label-icon align-middle fs-16 ms-2"></i> Excel
            </BButton>
            <BButton :disabled="disableConfirm" @click="showConfirmConfirmationModal = true" variant="soft-success"
                     class="btn-label waves-effect right waves-light"><i
                class="ri-check-double-line label-icon align-middle fs-16 ms-2"></i> Confirm
            </BButton>
          </div>
        </div>

        <BAlert v-if="missing_codes.length > 0" show variant="danger" class="alert-label-icon rounded-label fade show"
                role="alert"
        >
          <i class="ri-refresh-line label-icon"></i><strong>Not found codes</strong> -
          <div class="d-inline-flex gap-1">
              <span v-for="(code, index) in missing_codes" :key="`missing_code_${code}`">
                {{ code }}{{ index + 1 === missing_codes.length ? "" : "," }}
              </span>
          </div>
        </BAlert>
      </div>

      <div class="col-6 sticky-item">
        <template v-if="selected_row">
          <b-spinner v-if="selected_row.is_loading"></b-spinner>
          <iframe v-if="selected_row.img_type === 'pdf' && !selected_row.is_loading" :src="selected_row.img_path"
                  width="100%"
                  height="1200px" class="sticky-item"></iframe>
        </template>
      </div>

      <div class="col-6">

        <div :class="{
          'sticky-item pt-4 bg-white shadow': isScrolled
        }" style="z-index: 999; transition: .3s">

          <div class="row g-3 mb-4" v-if="selected_row">
            <div class="col-4">
              <input v-model="selected_row.number" :disabled="selected_row.is_saving" type="text" class="form-control"
                     id="smgs_number"
                     placeholder="SMGS Number">
            </div>
            <div class="col-4">
              <input v-model="selected_row.weight" :disabled="selected_row.is_saving" type="text" class="form-control"
                     id="weight" placeholder="Weight">
            </div>
            <div class="col-4">
              <input v-model="selected_row.date" :disabled="selected_row.is_saving" type="date" class="form-control"
                     id="date" placeholder="Date">
            </div>

            <div class="col-6">
              <Multiselect
                  @search-change="searchContainers($event)"
                  v-model="selected_row.container_id"
                  :options="container_options"
                  :searchable="true"
                  :disabled="selected_row.is_saving"
                  placeholder="Container name"/>
            </div>

            <div class="col-6">
              <Multiselect
                  @input="onWagonSelect($event)"
                  @search-change="searchWagons($event)"
                  v-model="selected_row.wagon_id"
                  :options="wagon_options"
                  :searchable="true"
                  :disabled="selected_row.is_saving"
                  placeholder="Wagon number"
                  :object="true"
              />
            </div>

            <div class="col-6">
              <Multiselect
                  @search-change="searchStations($event, 'departure')"
                  v-model="selected_row.departure_station_id"
                  :options="departure_options"
                  :searchable="true"
                  :disabled="selected_row.is_saving"
                  placeholder="Departure"
              />
            </div>

            <div class="col-6">
              <Multiselect
                  @search-change="searchStations($event, 'destination')"
                  v-model="selected_row.destination_station_id"
                  :options="destination_options"
                  :searchable="true"
                  :disabled="selected_row.is_saving"
                  placeholder="Destination"/>
            </div>

            <div class="col-6">
              <Multiselect
                  @search-change="searchCompanies($event, 'shipper')"
                  v-model="selected_row.shipper_id"
                  :options="shipper_options"
                  :searchable="true"
                  :disabled="selected_row.is_saving"
                  placeholder="Shipper"
              />
            </div>

            <div class="col-6">
              <Multiselect
                  @search-change="searchCompanies($event, 'consignee')"
                  v-model="selected_row.consignee_id"
                  :options="consignee_options"
                  :searchable="true"
                  :disabled="selected_row.is_saving"
                  placeholder="Consignee"/>
            </div>

            <template v-for="(forwarder, forwarder_index) in selected_row.smgs_codes"
                      :key="`forwarder_${forwarder.id}`"
            >

              <div class="col-4">
                <Multiselect
                    @change="onForwarderSelect($event, forwarder, forwarder_index)"
                    :options="forwarders_options" :searchable="true"
                    style="padding: 9px !important;"
                    placeholder="Consignee"
                    v-model="forwarder.forwarder"
                    :object="true"
                    :disabled="selected_row.is_saving"
                />
              </div>

              <div class="col-3">
                <input v-model="forwarder.code" type="text" class="form-control"
                       :class="{
                         'border-danger': missing_codes.includes(forwarder.code),
                       }"
                       :disabled="selected_row.is_saving"
                       id="forwarder_code_number" placeholder="Code">
              </div>
              <div class="col-3">
                <input v-model="forwarder.cost" type="number" class="form-control"
                       id="forwarder_code_price"
                       :disabled="selected_row.is_saving"
                       placeholder="Price">
              </div>

              <div class="col-2">
                <div class="d-flex gap-3">
                  <b-button
                      @click="addForwarder()"
                      :disabled="selected_row.is_saving"
                      v-if="forwarder_index === (selected_row.smgs_codes || []).length - 1" variant="soft-success"
                      class="w-100"
                  >
                    <font-awesome-icon
                        icon="fa-solid fa-plus"
                        class="fs-12 text-success"
                    />
                  </b-button>
                  <b-button v-if="(selected_row.smgs_codes || []).length > 1"
                            @click="removeForwarder(forwarder_index)"
                            variant="soft-danger" class="w-100"
                            :disabled="selected_row.is_saving"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash"
                                       class="fs-12 text-danger"/>
                  </b-button>
                </div>
              </div>
            </template>

            <div class="col-12 pb-2">
              <BAlert v-if="selected_row.validation" show variant="danger"
                      class="alert-label-icon rounded-label fade show"
                      role="alert"
              >
                <i class="ri-refresh-line label-icon"></i><strong>Validation</strong> -
                {{ selected_row.validation.reason || "Please, make sure you filled all the required fields" }}
              </BAlert>

              <b-button
                  :disabled="selected_row.disable_save || selected_row.is_saving"
                  @click="saveSelectedSMGS()" variant="soft-success" class="w-100">
                <span v-if="!selected_row.is_saving">Save</span>
                <b-spinner v-else style="width: 15px; height: 15px" variant="success"></b-spinner>
              </b-button>
            </div>

          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-nowrap">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">SMGS №</th>
              <th scope="col">Container</th>
              <th scope="col">Wagon</th>
              <th scope="col">Code №</th>
              <th scope="col">Weight</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in (document.smgses || [])" :key="item.id">
              <tr @click="selectPage(index)" class="align-middle" :class="{
                'bg-soft-success fw-bold': rowIsSelected(index)
              }">
                <th class="text-center" scope="row">
                  <template v-if="selected_row">
                    <b-spinner style="height: 16px; width: 16px"
                               v-if="(selected_row.is_saving || selected_row.is_loading) && rowIsSelected(index)"></b-spinner>
                    <span v-else>{{ index + 1 }}</span>
                  </template>
                  <span v-else>{{ index + 1 }}</span>
                </th>
                <td>{{ item.number || '-' }}</td>
                <td>{{ item.container_name || '-' }}</td>
                <td>{{ item.wagon_name || '-' }}</td>
                <td>
                  <div class="d-flex gap-2 align-items-center flex-wrap" style="max-width: 300px">
                    <span v-for="code in (item.smgs_codes || []).filter(i => i.forwarder)"
                          :key="`item_${item.id}_code_${code.id}`"
                          class="badge"
                          :class="{
                            'bg-danger text-white': missing_codes.includes(code.code),
                            'bg-success text-white': !missing_codes.includes(code.code) && rowIsSelected(index),
                            'bg-soft-dark text-body': !missing_codes.includes(code.code) && !rowIsSelected(index)
                          }"
                    >
                      {{ code.code }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ item.weight || '-' }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-card>

</template>

<style scoped>
.sticky-item {
  position: sticky;
  top: 70px; /* Distance from the top of the viewport */
  z-index: 100; /* Ensures it stays above other elements */
  transition: .3s;
}
</style>